<template>
  <div
    class="collapse-icon mt-2 collapse-margin accordion d-flex align-items-center"
  >
    <unshare-item-modal
      v-model="isUnshareModalVisible"
      model-type="faq"
      @remove-item="removeItem()"
      @modal-invisible="isUnshareModalVisible = false"
    />
    <b-card class="faqs-card flex-grow-1">
      <div
        v-b-toggle="`collapse-${faqs.key}`"
        class="card-header collapsed"
        data-toggle="collapse"
      >
        <span class="lead collapse-title"> {{ translatedQuestion }}</span>
      </div>

      <b-collapse
        :id="`collapse-${faqs.key}`"
        accordion="my-faqs"
        class="pl-1 answer-collapse"
      >
        <span v-html="translatedAnswer" />
      </b-collapse>
    </b-card>
    <feather-icon
      v-if="canEdit && $route.name !== 'Resumen'"
      icon="Edit2Icon"
      size="20"
      style="cursor: pointer"
      class="text-primary ml-2"
      @click="$emit('edit', faqs.key)"
    />
    <feather-icon
      v-if="canDelete && $route.name !== 'Resumen'"
      icon="Trash2Icon"
      size="20"
      style="cursor: pointer"
      class="text-primary edit-icon float-right ml-1"
      role="button"
      @click="(isUnshareModalVisible = true), (faqKey = faqs.key)"
    />
  </div>
</template>

<script>
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import { BCard, BCollapse, VBToggle } from "bootstrap-vue";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import UnshareItemModal from "@/@core/components/modal/UnshareItemModal.vue";

export default {
  components: {
    BCard,
    BCollapse,
    UnshareItemModal,
    ToastificationContentVue,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    faqs: {
      type: Object,
      required: true,
    },
    searchValue: String,
  },
  data() {
    return {
      slotProps: [],
      show: false,
      thumbnailURL: "",
      isUnshareModalVisible: false,
      faqKey: "",
    };
  },
  computed: {
    translatedQuestion() {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        this.faqs.question
      );
    },
    translatedAnswer() {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        this.faqs.answer
      );
    },
    canEdit() {
      return this.collective.isStaff;
    },
    canDelete() {
      return this.collective.isStaff;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
  },
  methods: {
    detail(item) {
      this.show = true;
      this.thumbnailURL = item;
    },
    async removeItem() {
      this.$emit("total-items");
      try {
        await this.$store.dispatch("deleteQuestion", {
          item: {
            itemType: "faqs",
            requestConfig: {
              key: this.faqKey,
            },
          },
        });

        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t("unshare.toast-messages.success", { item: "FAQ" }),
            icon: "CheckIcon",
            variant: "success",
          },
        });
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>

<style lang="scss" type="text/css"></style>
