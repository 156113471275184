<template>
  <div>
    <vertical-container
      id="faqs"
      :item-name="$t('faqs.title')"
      :searchValue="searchValue"
      :totalModifier="itemsTotal"
    >
      <template v-if="canView" v-slot:default="{ item }">
        <faq-collapse
          :faqs="item"
          :searchValue="searchValue"
          @edit="$emit('edit', $event)"
          @total-items="totalItems"
        />
      </template>
    </vertical-container>
  </div>
</template>

<script>
import VerticalContainer from "@/@core/components/containers/VerticalContainer.vue";
import FaqCollapse from "./FaqCollapse.vue";
import { checkPermissions } from "@/@core/utils/roles-utils";

export default {
  components: {
    VerticalContainer,
    FaqCollapse,
  },
  props: {
    searchValue: String,
  },
  data() {
    return {
      itemsTotal: false,
    };
  },
  computed: {
    collective() {
      return this.$store.state.collective.collective;
    },
    isStaff() {
      return this.collective.isStaff;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canView() {
      return (
        this.isStaff ||
        checkPermissions("view", "faq", this.loggedMemberRoles, this.collective)
      );
    },
  },
  methods: {
    totalItems() {
      this.itemsTotal = !this.itemsTotal;
    },
  },
};
</script>
